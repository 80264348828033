var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "container", staticStyle: { "margin-bottom": "10px" } },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-10" }, [
            _c("div", { attrs: { id: "vg-embed" } })
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }