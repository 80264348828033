










import {Component} from '@/decorators';
import Vue from 'vue';

@Component({})
export default class ErrorView extends Vue {
    public created(): void {
        let scriptTag = window.document.createElement('script');
        scriptTag.src = process.env.VUE_APP_VG_EMBED_SCRIPT;
        scriptTag.onload = this.loadOffers;
        window.document.head.appendChild(scriptTag);
    }

    private loadOffers() {
        window.vgEmbed.load({
            element: 'vg-embed',
            site: process.env.VUE_APP_VG_EMBED_API_URL,
            snippetId: process.env.VUE_APP_ERROR_PAGE_SNIPPET_ID,
            contact: {}
        });
    }
}
